import React from "react"
import PropTypes from 'prop-types';
//import { Link } from "gatsby"
import SubProgram from './subprogramCard'
import Layout from "../components/layout";
import {Typography, Grid} from '@material-ui/core'
import { withStyles} from '@material-ui/core/styles';

import {Image} from 'react-bootstrap';



const styles = theme =>({
  topTitle:{
      //marginTop: theme.spacing(3),
      
  },
  PrincipalImg:{
    width:"100%",

  },
  title:{
    fontFamily:"Arial Black",
    '@media (max-width: 477px)': {
      fontSize:"28px"
    },
  },
  font:{
    fontFamily:"Montserrat",
    textAlign: "justify",
    whiteSpace: "pre-line"
  }

});

class ProgrmaTemplate extends React.Component{
    render(){
    const { classes, pageContext } = this.props;

    const title = pageContext.data.title
    const altIMG = (pageContext.data.img!== '')?pageContext.data.title:''
    const image = pageContext.data.img
    const description = pageContext.data.description
    const content = pageContext.data.content
    console.log(pageContext.data)
    const sortByProperty = function (property, property2) {
      return function (x, y) {
          return ((x[property] === y[property]) ? 0 : ((x[property] > y[property]) ? 1 : -1));
      };
  };
    return (
        <Layout>
           <Grid
             container
             direction="column"
             alignItems="center"
             justify="center"
             className={classes.topTitle}
            >
             <Typography gutterBottom variant="h2" component="h1"className={classes.title}  >
              {title} 
            </Typography>

            {image!==''?
             <Image src={image}  className={classes.PrincipalImg} alt={altIMG} fluid rounded/> 
          : ''}
          </Grid>
         <Grid> 
             <Typography  component="p" className={classes.font}>
               {description}
             </Typography>
             <Typography component="p" className={classes.font}>
               {content}
             </Typography>
         </Grid>

        <Grid container>
        {
            pageContext.data.subProgram.sort(sortByProperty('title')).map(subprogram=>{
                return (
                 
                    <SubProgram state= {{subprogram}}/>
                    
                )
            }
            )
        }
        </Grid>
        </Layout>
    )
  }
}

ProgrmaTemplate.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(ProgrmaTemplate)