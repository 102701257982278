import React from "react"
//import {Button} from '@material-ui/core'
import { makeStyles} from '@material-ui/core/styles';
import { navigate } from "gatsby"
import {Grid} from '@material-ui/core'

import {
  Carousel,
  Container,
  Row,
  Col,
  //CardGroup,
  Card,
  CardDeck,
  CardColumns

} from 'react-bootstrap';

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 345,
        fontFamily: 'Montserrat'
      },
      media: {
        //height: 240,
        //width: 180,
        borderRadius: "8px",
        width: "180px",
        maxWidth:"180px",
        height: "250px",
        maxHeight:"250px",
        backgroundSize: "contain"
      },
      title:{
        width:"100%",
        fontFamily:"Arial Black",
        fontSize:20
      },
      btn:{
        width:"100%",
        height:"100%",
        color:"white",
        backgroundColor: "#ad1d21",
        fontFamily: 'Montserrat',
        '&:hover': {
          backgroundColor: '#911619',
          borderColor: '#911619',
          boxShadow: 'none',
        },
      }
}));

const SubProgram= ({subProgram}) =>{
    const classes = useStyles();  
    if(!subProgram) return null
    const handleClick= link => () => {
      navigate(link)
    }
    return (
<Grid
item
xs={12} sm={12} md={6} lg={4} xl={3} 
>
<Card>
  <Card.Img variant="top" src={subProgram.ImgUrl} />
    <Card.Body>
    {subProgram.ImgDescripcion !== null && subProgram.ImgDescripcion !=="" ? (
    <>
      <blockquote className="blockquote mb-0">
      <footer className="blockquote-footer">
        <cite title="Source Title">{subProgram.ImgDescripcion}</cite>
      </footer>
    </blockquote>
    </>
    ) : (
      <>
    </>
    )}
    
    </Card.Body>
</Card>
</Grid>
        
      );
}
export default SubProgram