import React from "react"
import { Card,Paper, CardMedia, CardContent, Typography, Grid} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { CardColumns} from 'react-bootstrap';
import SubProgramImgList from "./imgSubprograms"

const useStyles = makeStyles(theme => ({
    card: {
        marginTop: theme.spacing(2),
        width: '100%',
        minWidth: '300px'
      },
      date: {
        textAlign:'right'
      },
      bodyText:{
          marginLeft: theme.spacing(2),
          fontFamily:"Montserrat",
          textAlign: "justify",
          whiteSpace: "pre-line"

      },
      moreButton:{
       marginTop:'-30px'
      },
      title:{
      width:"100%",
      height:"20%",
      backgroundColor:"#ad1d21",
      opacity:"0.75",
      color:"white",
      display:"flex",
      justifyContent: "center",
      alignItems: "center",
      marginBottom:"10px",
      fontFamily:"Arial Black",
      fontSize:"20px"


      },
      font:{
        fontFamily:"Montserrat"
      },
      ImgAre:{
        padding:"1%"
      },
      
}));

//class ArticleCard extends React.Component{
//  render(){
const  ArticleCard = ({ state }) =>{
    //const { articleData, classes } = this.props;
    const classes = useStyles();
/*     if(!articleData){
      articleData= {
        title: '',
        img:'',
        createdAt:'',
        body:'',
        url: ''
      }
    } */
    //const { state = {} } = location
    if (!state) return null
    
    const { subprogram } = state
    
    //console.log(articleData)
    const title = subprogram.title
    const altIMG = (subprogram.img!== '')?subprogram.title:''
   /*  moment.locale('es-US')
    const date = moment('28/12/2020', 'DD/MM/YYYY').format('LL')*/
    const image = (subprogram.img!=='')? subprogram.img: ''
    const bodyText = subprogram.content//"Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica, as well as most oceanic island chains. The group is paraphyletic as it excludes the snakes and Amphisbaenia; some lizards are more closely related to these two excluded groups than they are to other lizards. Lizards range in size from chameleons and geckos a few centimeters long to the 3 meter long Komodo dragon. Most lizards are quadrupedal, running with a strong side-to-side motion. Others are legless, and have long snake-like bodies. Some such as the forest-dwelling Draco lizards are able to glide. They are often territorial, the males fighting off other males and signalling, often with brightly colours, to attract mates and to intimidate rivals. Lizards are mainly carnivorous, often being sit-and-wait predators; many smaller species eat insects, while the Komodo eats mammals as big as water buffalo. Lizards make use of a variety of antipredator adaptations, including venom, camouflage, reflex bleeding, and the ability to sacrifice and regrow their tails."    

return (
        <Grid  item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Paper>
        <Card className={classes.card}>
          <CardContent>
              <Grid container>
            <Typography className={classes.title}>
              {title}
            </Typography>
            </Grid>
            <Grid container>
            <Grid item  xs={12} sm={12} md={12} lg={12} xl={12}
            justify="center"
            alignItems= 'center'
            className={classes.imgContainer}
            >
          {image!==''?<CardMedia
            component="img"
            alt= {altIMG}
            //height= '100%'//"150vh"
            //width= '100%'//"200vh"
            image= {image}
            title= {altIMG}
          />: ''}
          </Grid>
            <Grid item  xs={12} sm={12} md={12} lg={12} xl={12} >
            <Typography align='justify' className={classes.bodyText} variant="body2" color="textSecondary" component="p">
              {bodyText}
            </Typography>
            </Grid>
            </Grid>
          </CardContent>
</Card>          
      {
        subprogram.ImgList.length > 0 ? (
          <Grid className={classes.ImgAre} container  xs={12} sm={12} md={12} lg={12} xl={12} spacing={1} >
          {subprogram.ImgList.map((Article , index) => {
          return (
                  <React.Fragment>
                     <SubProgramImgList subProgram={Article} />
                  </React.Fragment>      
                  );
                })}
        </Grid>
          ) : (
            <Paper className={classes.Table}>
          
          </Paper>
     )}
    
      
      </Paper>
      </Grid>
    )
}



export default (ArticleCard)




